<template>
  <article class="partner-page">
    <h1 class="sr-only">Electrify America Volkswagen Page</h1>
    <Hero heading="Power the full potential of your Volkswagen ID.4" headlineLevel="h2" :subheading="true">
      <template slot="subheading">
        <span
          >Unlock infinite possibilities charging on Electrify America’s DC fast charging network—compliments of
          Volkswagen.
          <ReferenceLink id="partner-reference-link" num="1" v-if="id4YearChosen !== '2024'">
            {{ reference1Text }}
          </ReferenceLink>
        </span>
      </template>
      <template slot="image">
        <picture v-if="id4YearChosen === '2023'">
          <img src="@/assets/images/Partners/Bespoke/image_02.jpg" alt="" />
        </picture>
        <picture v-else-if="id4YearChosen === '2024'">
          <img src="@/assets/images/Partners/Bespoke/Top_MY24_ID_4_Running_Exterior.jpg" alt="" />
        </picture>
        <picture v-else>
          <source media="(min-width:750px)" srcset="@/assets/images/Partners/Bespoke/Bespoke_img-001@2x.jpg" />
          <source media="(min-width:1200px)" srcset="@/assets/images/Partners/Bespoke/Bespoke_img-001@3x.jpg" />
          <img src="@/assets/images/Partners/Bespoke/Bespoke_img-001.jpg" alt="" />
        </picture>
      </template>
    </Hero>
    <TwoColumnContentBlock heading="Evolve with Electrify America" headlineLevel="h3">
      <p>
        The future of electric mobility can’t wait, and with Electrify America, it doesn’t have to. We’ve built a
        convenient, reliable, and growing network of over 800 electric vehicle charging stations coast-to-coast—along
        major highways and cross-country routes—to take electric vehicle (EV) drivers farther than ever before.
      </p>
      <PartnerDropdown id="id4-select" v-model="id4YearChosen" :options="id4DropdownOptions">
        <template slot="label">
          <div id="partner-label-container">
            <label for="id4-select"> Select your Volkswagen ID.4® model year</label> &nbsp;
            <ReferenceLink id="partner-reference-link" num="1" v-if="id4YearChosen !== '2024'">
              {{ reference1Text }}
            </ReferenceLink>
          </div>
        </template>
      </PartnerDropdown>
      <p v-if="id4YearChosen === '2021'">
        Your 2021 Volkswagen ID.4® comes with 3 years of unlimited complimentary charging.
      </p>
      <p v-if="['2022', '2023'].includes(id4YearChosen)">
        {{ `Your ${id4YearChosen} Volkswagen ID.4® comes with 3 years of 30-minute complimentary charging.` }}
      </p>
      <p v-if="id4YearChosen === '2024'">
        Your 2024 Volkswagen ID.4® comes with 3 years of Pass+ Pricing with 500 kWh of complimentary charging
        included<ReferenceLink :num="1"
          >Available on new model year 2024 Volkswagen ID.4 vehicles purchased from an authorized U.S.-based Volkswagen
          dealer. Thirty-six (36) months of Pass+ pricing and 500 kWh of complimentary DC Fast or L2 charging begins
          upon vehicle purchase. Complimentary charging does not include any idle fees and their associated or
          applicable taxes assessed at the charging station. You will be responsible for paying such fees through your
          account if you incur them during a charging session. Non-transferable. Not available for commercial use, such
          as ride-hailing and ridesharing. Requires Electrify America account, app, acceptance of
          <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
          <a class="link" :href="'/vw-id4-charging-plan-disclosure'">Promotion Terms and Conditions</a>, enrollment in
          VW ID.4 Charging Plan, and $10 deposit to pre-fund idle fees, which may be automatically reloaded. See Terms
          of Use for details. Unused idle fee deposit will be refunded when account is closed. After beneﬁts terminate
          or expire, your account will convert to a basic Electrify America “Pass” account. Your actual charging speed
          may vary. </ReferenceLink
        >. For Full program detail,
        <router-link
          aria-label="VW ID.4 Charging Plan"
          class="link"
          :to="{ name: '2024-vw-id4-charging-plan-disclosure-en' }"
          >click here</router-link
        >.
      </p>
      <p v-if="id4YearChosen === '2024' || id4YearChosen === '2023'">
        Plug&Charge functionality will be available for use with this plan. Plug&Charge can help ID.4 owners have a
        better charging experience by creating a secure, convenient, and effortless way to pay for charging sessions on
        the Electrify America network. Just plug in and we'll handle the rest.
      </p>
      <p>ID.4 drivers can focus on the roads and possibilities ahead, leaving gas stations and MPG behind.</p>
      <router-link class="button" :to="{ name: 'locate-charger-en' }">Locate a Charger</router-link>
    </TwoColumnContentBlock>
    <TwoColumnContentBlock heading="Go with freedom" headlineLevel="h3">
      <template #image>
        <img
          loading="lazy"
          v-if="['2021', '2022'].includes(id4YearChosen)"
          src="@/assets/images/Partners/Bespoke/Bespoke_img-002.png"
          alt="Image of 2021 Volkswagen ID4"
        />
        <img
          loading="lazy"
          v-else-if="id4YearChosen === '2023'"
          src="@/assets/images/Partners/Bespoke/Bespoke_img-001@2x.jpg"
          alt=""
        />
        <img
          loading="lazy"
          v-else-if="id4YearChosen === '2024'"
          src="@/assets/images/Partners/Bespoke/Middle_MY24_ID_4_Product_Exterior.jpg"
          alt=""
        />
        <img loading="lazy" v-else src="@/assets/images/Partners/Bespoke/image_02.jpg" alt="" />
      </template>
      <p v-if="['2022', '2023'].includes(id4YearChosen)">
        Get going with 3 years of 30-minute complimentary charging on Electrify America’s DC fast charging network. Just
        follow these simple steps:
      </p>
      <p v-else-if="id4YearChosen == '2021'">
        Get going with 3 years of unlimited complimentary charging on Electrify America’s DC fast charging network. Just
        follow these simple steps:
      </p>
      <p v-else-if="id4YearChosen == '2024'">
        Get going with 3 years of Pass+ Pricing and 500 kWh of complimentary charging on Electrify America’s DC fast
        charging network. Just follow these simple steps:
      </p>
      <p v-else>Get going with Electrify America’s DC fast charging network. Just follow these simple steps:</p>
      <ul>
        <li>
          <strong>Step 1:</strong> Download the Electrify America app,
          <ReferenceLink num="2"
            >Your mobile carrier’s normal messaging, data, and other rates and fees will apply to your use of the
            Electrify America mobile application.</ReferenceLink
          >
          create your account, and select “Set Up Public Charging.”
        </li>
        <li>
          <strong>Step 2:</strong> Select “Choose Membership Plan” then “Premium Offers.”
          <ul>
            <li>Or, search for Premium Offer by vehicle information.</li>
            <li>
              Existing Electrify America members may select “Premium Offers” in the Account tab, then “I have an
              enrollment code.”
            </li>
          </ul>
        </li>
        <li>
          <strong>Step 3:</strong>
          <span v-if="id4YearChosen === '2024'">
            Input your 6-digit enrollment code, which you will receive from your ID.4 dealer, and select the VW ID.4
            Charging Plan. Input your vehicle 17-digit VIN</span
          >
          <span v-else-if="id4YearChosen">
            {{
              ` Input your 6-digit enrollment code, which you will receive from your Volkswagen dealer, and select the
                    ${
                      id4YearChosen === '2023' ? id4YearChosen : ''
                    } ID.4 Charging Plan. Input your vehicle 17-digit VIN`
            }}
          </span>
          <span v-else>
            Input your 6-digit enrollment code, which you will receive from your Volkswagen dealer, and select the ID.4
            Charging Plan. Input your vehicle 17-digit VIN
          </span>
          <ReferenceLink num="3"
            >The VIN can usually be found on the driver’s side dashboard, near or under the bottom portion of the
            windshield.</ReferenceLink
          >
          for verification.
        </li>
        <li><strong>Step 4:</strong> Accept Plan Disclosure, then tap “Select this plan.”</li>
        <li>
          <strong>Step 5:</strong> Enter payment info and set up auto-reload.
          <ul>
            <li>
              You will need to enter a payment method
              <ReferenceLink num="4">
                <span v-if="!id4YearChosen"
                  >To help ensure you can charge uninterrupted, your Electrify America account balance automatically
                  reloads when it falls below $5. You’ll be billed the auto-reload amount you select in the app, the
                  first time you charge at an Electrify America station when vehicle reaches full charge or after the
                  initial 30-minutes of each applicable session.</span
                >
                <span v-if="id4YearChosen === '2021'"
                  >To help ensure you can charge uninterrupted, your Electrify America account balance automatically
                  reloads when it falls below $5. You’ll be billed the auto-reload amount you select in the app, the
                  first time you charge at an Electrify America station when vehicle reaches full charge.
                </span>
                <span v-if="['2022', '2023', '2024'].includes(id4YearChosen)"
                  >To help ensure you can charge uninterrupted, your Electrify America account balance automatically
                  reloads when it falls below $5. You’ll be billed the auto-reload amount you select in the app, the
                  first time you charge at an Electrify America station, when vehicle reaches full charge, and after the
                  initial 30-minutes of each applicable session.</span
                > </ReferenceLink
              ><span v-if="['2022', '2023'].includes(id4YearChosen)">
                to cover charging after 30 minutes, any incurred Idle Fees
              </span>
              <span v-else> to cover any incurred Idle Fees </span>
              <ReferenceLink num="5"
                >Drivers who do not unplug and move their vehicle ten minutes after their charging session is complete
                will be charged a $0.40 per-minute idle fee. This fee encourages drivers to move their car promptly so
                that others can use the charger. You can be notified when your charging session is complete by text
                message* or see it on the charger screen. <br />
                <br />
                <div>
                  <em
                    >*Your mobile carrier’s normal messaging, data, and other rates and fees will apply to your use of
                    the Electrify America mobile application.</em
                  >
                </div></ReferenceLink
              >
              <span v-if="['2022', '2023'].includes(id4YearChosen)">, and any applicable taxes. </span>
              <span v-else> or applicable taxes. </span>
            </li>
          </ul>
        </li>
        <li v-if="id4YearChosen === '2023' || id4YearChosen === '2024'">
          <strong>Step 6:</strong> Activate Plug&Charge by following the steps below for a seamless charging experience
        </li>
      </ul>
      <p>
        Offer enrollment can be smooth as your journeys, but if you encounter any roadblocks, call 1-833-632-2778 to
        contact Electrify America Customer Assistance, available 24/7.
      </p>
      <div class="partner-page__cta">
        <router-link class="button partner-page__cta__link" :to="{ name: 'mobile-app-en' }"
          >Download The App</router-link
        >
      </div>
    </TwoColumnContentBlock>
    <TwoColumnContentBlock
      heading="Take charge with Plug&Charge"
      headlineLevel="h3"
      v-if="['2023', '2024'].includes(id4YearChosen)"
    >
      <template #image>
        <picture>
          <source media="(min-width: 750px)" srcset="@/assets/images/Hyundai/hero.png" />
          <img src="@/assets/images/Hyundai/hero-mobile.png" alt="Electrify America charging stations at dusk"
        /></picture>
      </template>
      <p>
        Our Plug&Charge
        <ReferenceLink num="6"
          >Plug&Charge is available to original owners only who are enrolled in the ID.4 premium offer plan and is not
          currently available for Pass and Pass+ customers.</ReferenceLink
        >
        technology can help EV owners have a better charging experience by creating a convenient and effortless way to
        pay for charging sessions on the Electrify America network.
      </p>
      <strong>Activating Plug&Charge in the Electrify America App</strong>
      <ul>
        <li>
          <strong>Step 1:</strong>
          {{
            ` After enrolling in ${
              id4YearChosen === '2024' ? 'the VW' : id4YearChosen
            } ID.4 Charging Plan, select “Account” at the bottom of your
          screen within the Electrify America App`
          }}
        </li>
        <li>
          <strong>Step 2:</strong>
          {{
            `Go to “Plans” in the menu and select your ${
              id4YearChosen === '2024' ? 'ID.4 Charging Plan' : 'MY23 ID.4 Charging Plan'
            } `
          }}
        </li>
        <li>
          <strong>Step 3:</strong>
          Scroll down and select “Plug&Charge Activate Now”
        </li>
        <li><strong>Step 4:</strong> Follow the instructions in the app and confirm “Activate Plug&Charge”</li>
        <li>
          <strong>Step 5:</strong> Select “OK” to activate Plug&Charge
          <ReferenceLink num="7"
            >Activation usually takes a few minutes, but may take longer depending on your vehicle and its cellular
            connectivity. If you are selling your vehicle or you no longer wish to use Plug&Charge, you must deactivate
            Plug&Charge in the Electrify America mobile app, otherwise your credit card may be charged on the next
            plug-in. Please review you owner’s manual for any additional information regarding activating Plug&Charge
            for your specific vehicle.</ReferenceLink
          >
        </li>
      </ul>
      <p>Once activated our Plug&Charge system is simple to use. All you need to do is follow the steps below.</p>
      <ul>
        <li><strong>Step 1:</strong> <strong>Drive</strong> to any Electrify America DC fast charging station.</li>
        <li>
          <strong>Step 2:</strong> <strong>Plug in.</strong> Plug the charging connector into your electric vehicle.
        </li>
        <li>
          <strong>Step 3:</strong> <strong>Check the charge.</strong> Check the charger screen to ensure your charge has
          begun.
        </li>
        <li>
          <strong>Step 4:</strong> <strong>Relax</strong> Now that your vehicle is charging, monitor your progress in
          your charging app.
        </li>
      </ul>
      <p v-if="id4YearChosen === '2023'">
        Enjoy 30 minutes of complimentary charging for three years from your vehicle purchase date<ReferenceLink
          num="1"
        >
          3 years of included 30-minute charging sessions on the model year 2023 ID.4 vehicles begins upon vehicle
          purchase. Non-transferable. Not available for commercial use, such as ridesharing. Requires Electrify America
          mobile app, account, enrollment in 2023 ID.4 Charging Plan, acceptance of Plan Disclosure, and $10 deposit to
          pre- fund Idle Fees and charging after 30 minutes. User is responsible for Idle Fees, which apply beginning 10
          minutes after charge session stops or vehicle reaches full charge. After the initial 30 minutes of each
          applicable session, charging continues uninterrupted at then-current Electrify America Pass pricing. There is
          a 60-minute wait-period between charging sessions in which you receive the 30 minutes of charging under this
          promotion. In the event of suspected fraud or abuse or other unforeseen events, Volkswagen of America may
          discontinue or modify the offer in its sole discretion.</ReferenceLink
        >. The prevailing Electrify America Pass pricing applies after this 30-minute period has passed.
      </p>
      <p v-else-if="id4YearChosen === '2024'">
        Enjoy 3 years of Pass+ Pricing and 500 kWh of complimentary charging from your vehicle purchase
        date<ReferenceLink :num="1"
          >Available on new model year 2024 Volkswagen ID.4 vehicles purchased from an authorized U.S.-based Volkswagen
          dealer. Thirty-six (36) months of Pass+ pricing and 500 kWh of complimentary DC Fast or L2 charging begins
          upon vehicle purchase. Complimentary charging does not include any idle fees and their associated or
          applicable taxes assessed at the charging station. You will be responsible for paying such fees through your
          account if you incur them during a charging session. Non-transferable. Not available for commercial use, such
          as ride-hailing and ridesharing. Requires Electrify America account, app, acceptance of
          <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
          <a class="link" :href="'/vw-id4-charging-plan-disclosure'">Promotion Terms and Conditions</a>, enrollment in
          VW ID.4 Charging Plan, and $10 deposit to pre-fund idle fees, which may be automatically reloaded. See Terms
          of Use for details. Unused idle fee deposit will be refunded when account is closed. After beneﬁts terminate
          or expire, your account will convert to a basic Electrify America “Pass” account. Your actual charging speed
          may vary. </ReferenceLink
        >. The prevailing Electrify America Pass pricing applies after the three years has passed.
      </p>
      <router-link class="button" :to="{ name: 'locate-charger-en' }">Locate a Charger</router-link>
    </TwoColumnContentBlock>
  </article>
</template>

<script>
import Hero from '@/components/Hero/Hero.vue';
import ReferenceLink from '@/components/ReferenceLink/ReferenceLink.vue';
import PartnerDropdown from '@/components/PartnerDropdown/PartnerDropdown.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  components: {
    ReferenceLink,
    PartnerDropdown,
    Hero,
    TwoColumnContentBlock,
  },
  metaInfo: {
    title: 'Volkswagen  | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/vw/' }],
  },
  data() {
    return {
      id4YearChosen: null,
    };
  },
  computed: {
    id4DropdownOptions() {
      const options = [
        { text: 'Select...', value: null },
        { text: '2024 Volkswagen ID.4 ®', value: '2024' },
        { text: '2023 Volkswagen ID.4 ®', value: '2023' },
        { text: '2022 Volkswagen ID.4 ®', value: '2022' },
        { text: '2021 Volkswagen ID.4 ®', value: '2021' },
      ];
      return options;
    },
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    reference1Text() {
      if (this.id4YearChosen == null) {
        return '3 years of included charging on the ID.4 vehicle begins upon vehicle purchase. Non-transferable. Not available for commercial use, such as ridesharing. Requires Electrify America mobile app, account, enrollment in ID.4 Charging Plan, acceptance of Plan Disclosure, and $10 deposit to pre-fund Idle Fees, charging after 30 minutes, and any applicable taxes. User is responsible for Idle Fees, which apply beginning 10 minutes after charge session stops or vehicle reaches full charge. After the initial 30 minutes of each applicable session, charging continues uninterrupted at then-current Electrify America Pass pricing. There is a 60-minute wait-period between charging sessions in which you receive the complimentary 30 minutes of charging under this promotion. In the event of suspected fraud or abuse or other unforeseen events, Volkswagen of America may discontinue or modify the offer in its sole discretion. Frequent and consecutive charging can permanently decrease battery capacity, which may not be covered by your vehicle’s warranty.';
      }
      if (this.id4YearChosen === '2021') {
        return 'Available on MY21 ID.4 vehicles. 3 years of unlimited charging begins upon vehicle purchase. Non-transferable. Not available for commercial use, such as ridesharing. Requires Electrify America account, app, enrollment in ID.4 Charging Plan, and acceptance of Terms of Use. After user-initiated charge session stop or vehicle reaches full charge or charging otherwise stops, and 10-min grace period, Idle Fees will apply and user is responsible. In the event of suspected fraud or abuse or other unforeseen events, Volkswagen of America may discontinue or modify the offer in its sole discretion.';
      }
      if (this.id4YearChosen === '2022') {
        return '3 years of included 30-minute charging sessions on the MY22 ID.4 vehicles begins upon vehicle purchase. Non-transferable. Not available for commercial use, such as ridesharing. Requires Electrify America mobile app, account, enrollment in 2022 ID.4 Charging Plan, acceptance of Plan Disclosure, and $10 deposit to pre-fund Idle Fees and charging after 30 minutes. User is responsible for Idle Fees, which apply beginning 10 minutes after charge session stops or vehicle reaches full charge. After the initial 30 minutes of each applicable session, charging continues uninterrupted at then-current Electrify America Pass pricing. There is a 60-minute wait-period between charging sessions in which you receive the 30 minutes of charging under this promotion. In the event of suspected fraud or abuse or other unforeseen events, Volkswagen of America may discontinue or modify the offer in its sole discretion. Frequent and consecutive charging can permanently decrease battery capacity, which may not be covered by your vehicle’s warranty.';
      }
      return '3 years of included 30-minute charging sessions on the model year 2023 ID.4 vehicles begins upon vehicle purchase. Non-transferable. Not available for commercial use, such as ridesharing. Requires Electrify America mobile app, account, enrollment in 2023 ID.4 Charging Plan, acceptance of Plan Disclosure, and $10 deposit to pre-fund Idle Fees and charging after 30 minutes. User is responsible for Idle Fees, which apply beginning 10 minutes after charge session stops or vehicle reaches full charge. After the initial 30 minutes of each applicable session, charging continues uninterrupted at then-current Electrify America Pass pricing. There is a 60-minute wait-period between charging sessions in which you receive the 30 minutes of charging under this promotion. In the event of suspected fraud or abuse or other unforeseen events, Volkswagen of America may discontinue or modify the offer in its sole discretion. Frequent and consecutive charging can permanently decrease battery capacity, which may not be covered by your vehicle’s warranty.';
    },
  },
};
</script>
